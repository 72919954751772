import { Directive, HostListener, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@usheru-hff/authentication/domain';
import { AuthenticationModalService } from './authentication-modal.service';

@Directive({
  selector: '[userCta]',
  standalone: true
})
/**
 * Directive that checks if cookies are enabled before emiting an event to "onClickAfterCheck" only if cookies required
 * are enabled
 */
export class UserCtaDirective {
  constructor(
    private authService: AuthService,
    private uiAuthService: AuthenticationModalService
  ) {}
  @Output() authorizedClick = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.authService.isLoggedIn()) {
      this.uiAuthService.presentLoginOptions();
      // todo: ask for login
    } else {
      this.authorizedClick.emit(this.authService.user);
    }
  }
}
