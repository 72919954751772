import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private showModalSource = new BehaviorSubject<any>(null);
  showModal$ = this.showModalSource.asObservable();

  openModal(component: any, config: { params?: any; header?: string }) {
    this.showModalSource.next({ open: true, component: component, params: config.params, header: config.header });
  }

  closeModal() {
    this.showModalSource.next({ open: false });
  }
}
