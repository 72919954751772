import { inject, Injectable, Type } from '@angular/core';
import { Params, Router } from '@angular/router';

import { ChannelService } from '@usheru-hff/sdk/channel';
import { ModalService } from '@usheru-hff/shared/utils-modal';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationModalService {
  private readonly modalService = inject(ModalService);
  private readonly router = inject(Router);
  private readonly channelService = inject(ChannelService);
  hasHandledRegister = false;

  async openForm(form: FormType): Promise<void> {
    const { AuthenticationModalComponent } = await import('@usheru-hff/authentication/feature-authentication-modal');
    this.modalService.openModal(AuthenticationModalComponent as Type<any>, {
      params: { openForm: form }
    });
  } 

  async presentLoginOptions(): Promise<void> {
    const { AuthenticationModalComponent } = await import('@usheru-hff/authentication/feature-authentication-modal');
    this.modalService.openModal(AuthenticationModalComponent as Type<any>, {
      params: { openForm: 'new-user-login' }
    });
  }

  public redirectToLogin(queryParams?: Params, redirectUrl?: string): void {
    if (this.channelService.isCommunityEnabled()) {
      if (redirectUrl) this.router.navigate([redirectUrl]);
      this.openForm('login');
    } else {
      this.router.navigate(['/auth/login'], { queryParams });
    }
  }
}

type FormType = 'login' | 'register' | 'new-user-login' | 'recover-password' | null;
